import React, { type ReactNode, useId, useRef, useState } from 'react'
import { Input, type InputProps } from '~/components/ui/inputs/input.tsx'
import { Label } from '~/components/ui/inputs/label.tsx'
import {
  Checkbox,
  type CheckboxProps,
} from '~/components/ui/inputs/checkbox.tsx'
import {
  Textarea,
  type TextareaProps,
} from '~/components/ui/inputs/textarea.tsx'
import { RadioGroup, type RadioGroupProps } from './radio-group.tsx'
import { dateToInput } from '~/utils/strings.ts'
import { Combobox, type ComboboxProps } from './combobox.tsx'
import {
  type DatePickerProps,
  DatePickerSelect,
} from './select/date-picker-select.tsx'
import { Select, type SelectProps } from './select/select.tsx'
import { StarsRating, type StarsRatingProps } from '../stars.tsx'
import { useInputControl } from '@conform-to/react'
import { cn } from '~/utils/misc.tsx'
import { TimeInput, type TimeInputProps } from './time-input.tsx'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors
  id?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map(e => (
        <li key={e} className="text-[10px] text-danger">
          {e}
        </li>
      ))}
    </ul>
  )
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement> & {
    asChild?: boolean
  }
  inputProps: Partial<InputProps>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  const variant = inputProps.variant ?? 'filled'
  return (
    <div className={cn('block w-full', className)}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <Input
        variant={variant}
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      />
      {errorId && (
        <div className="px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function TimeField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement> & {
    asChild?: boolean
  }
  inputProps: Partial<TimeInputProps>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  const variant = inputProps.variant ?? 'filled'

  return (
    <div className={cn('w-full', className)}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <TimeInput
        variant={variant}
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  textareaProps: TextareaProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
      />
      {errorId && (
        <div className="px-4 pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function CheckboxField({
  labelProps,
  checkboxProps,
  errors,
  className,
}: {
  labelProps?: JSX.IntrinsicElements['label']
  checkboxProps: CheckboxProps & {
    name: string
    form: string
    value?: string
  }
  errors?: ListOfErrors
  className?: string
}) {
  const { key, ...otherCheckboxProps } = checkboxProps
  const fallbackId = useId()
  const checkedValue = checkboxProps.value ?? 'on'

  // To emulate native events that Conform listen to:
  // See https://conform.guide/integrations
  const input = useInputControl({
    key,
    name: checkboxProps.name,
    formId: checkboxProps.form,
    initialValue: checkboxProps.defaultChecked ? checkedValue : undefined,
  })
  const id = checkboxProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <div className="flex gap-2">
        <Checkbox
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...otherCheckboxProps}
          onCheckedChange={state => {
            input.change(state.valueOf() ? checkedValue : '')
            checkboxProps.onCheckedChange?.(state)
          }}
          onFocus={event => {
            input.focus()
            checkboxProps.onFocus?.(event)
          }}
          onBlur={event => {
            input.blur()
            checkboxProps.onBlur?.(event)
          }}
          type="button"
        />
        <label
          htmlFor={id}
          className="mb-0 self-center text-body-xs text-foreground-muted"
          {...labelProps}
        />
      </div>
      {errorId && (
        <div className="px-4 pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export type AutocompleteOption = {
  label: ReactNode
  labelTrigger?: ReactNode
  value: any
}

export function ComboboxField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement> &
    ComboboxProps & {
      key: any
      name: string
      form: string
      value?: string
    }
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const {
    key,
    id: inputId,
    options,
    optionsClassName,
    triggerClassName,
    leftIcon,
    onInputChange,
    value: valueProp,
    defaultValue,
    ...otherInputProps
  } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  const value = valueProp ?? defaultValue

  const shadowInputRef = useRef<any>(null)
  const input = useInputControl({
    key,
    name: inputProps.name,
    formId: inputProps.form,
    initialValue: value,
  })

  return (
    <div className={cn(className)}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <input
        ref={shadowInputRef}
        {...otherInputProps}
        value={value}
        hidden={true}
      />
      <Combobox
        id={id}
        {...{ ...(inputProps as any) }}
        value={value}
        onChange={value => {
          if (!value) return
          input.change(String(value))
          // inputProps.onChange?.(value as any)
        }}
        onBlur={input.blur}
        onFocus={input.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function RadioGroupField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: RadioGroupProps &
    React.InputHTMLAttributes<HTMLInputElement> & {
      key: any
      name: string
      form: string
      value?: string
    }
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { id: inputId, key, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const shadowInputRef = useRef<any>(null)
  const input = useInputControl({
    key,
    name: inputProps.name,
    formId: inputProps.form,
    initialValue: inputProps.defaultValue,
  })

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <input ref={shadowInputRef} {...otherInputProps} className="hidden" />
      <RadioGroup
        id={id}
        {...(inputProps as any)}
        onChange={value => {
          if (!value) return
          input.change(value)
          input.blur()
          inputProps.onChange?.(value as any)
        }}
        // onBlur={control.blur}
        onFocus={input.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function DatePickerField({
  inputProps,
  errors,
  className,
}: {
  inputProps: React.InputHTMLAttributes<HTMLInputElement> &
    DatePickerProps & {
      key: any
      name: string
      form: string
      value?: string | Date
    }
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { id: inputId, key, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const [value, setValue] = useState(
    inputProps.defaultValue ?? inputProps.value ?? '',
  )

  const shadowInputRef = useRef<any>(null)
  const input = useInputControl({
    key,
    name: inputProps.name,
    formId: inputProps.form,
  })

  return (
    <div className={className}>
      <input ref={shadowInputRef} {...otherInputProps} hidden={true} />
      <DatePickerSelect
        {...{ ...(inputProps as any) }}
        onChange={date => {
          const newValue = dateToInput(date)
          if (!newValue) return
          setValue(newValue)
          input.change(newValue)
          input.blur()
          inputProps.onChange?.(newValue as any)
        }}
        date={value}
        onFocus={input.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function SelectField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement> &
    SelectProps & {
      key: any
      name: string
      form: string
      value?: string
    }
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { id: inputId, key, leftIcon, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const [value, setValue] = useState(
    inputProps.defaultValue ?? inputProps.value ?? '',
  )

  const shadowInputRef = useRef<any>(null)
  const control = useInputControl({
    key,
    name: inputProps.name,
    formId: inputProps.form,
  })

  return (
    <div className={className}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <input ref={shadowInputRef} {...otherInputProps} hidden={true} />
      <Select
        id={id}
        {...{ ...(inputProps as any), value, setValue }}
        onChange={(value: any) => {
          control.change(value)
          control.blur()
        }}
        // onBlur={control.blur}
        onFocus={control.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function StarsRatingField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: StarsRatingProps & {
    key: any
    name: string
    form: string
    value?: string
  }
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { key, id: inputId, size, onChange, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const [value, setValue] = useState(
    inputProps.defaultValue ?? inputProps.value ?? '',
  )

  const shadowInputRef = useRef<any>(null)
  const input = useInputControl({
    key,
    name: inputProps.name,
    formId: inputProps.form,
  })

  return (
    <div className={className}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <input ref={shadowInputRef} {...otherInputProps} hidden={true} />
      <StarsRating
        id={id}
        editable
        {...{ ...(inputProps as any), value, setValue }}
        onChange={(value: number) => {
          input.change(String(value))
          inputProps.onChange?.(value as any)
        }}
        onBlur={input.blur}
        onFocus={input.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}
