import { useState } from 'react'
import { Input, type InputProps } from './input.tsx'

export type TimeInputProps = InputProps & {
  value?: string
  defaultValue?: string
}

export const TimeInput = ({
  onChange,
  value: valueProp,
  defaultValue,
  ...props
}: TimeInputProps) => {
  const [value, setValue] = useState<string>(valueProp ?? defaultValue ?? '')

  function handleChange(e: any) {
    const inputTime: string = e.target.value
    const lastChar = inputTime.slice(-1)

    // if not valid character, return
    if (
      (inputTime.length > value.length && isNaN(Number(lastChar))) ||
      inputTime.length > 5
    ) {
      return setValue(value)
    }

    // validate correct digits
    if (inputTime.length === 1 && Number(inputTime) > 2) {
      return setValue(`0${inputTime}:`)
    }
    if (inputTime.length === 2 && Number(inputTime) > 24) {
      return setValue(value)
    }
    // 24:4 -> 24:
    if (
      inputTime.length === 4 &&
      Number(inputTime.slice(0, 2)) >= 24 &&
      Number(inputTime.slice(3)) > 0
    ) {
      return setValue(value)
    }
    // 23:9 -> 23:09
    if (inputTime.length === 4 && Number(lastChar) > 6) {
      return setValue(`${value}0${lastChar}`)
    }
    // 23:403 -> 23:40
    if (inputTime.length === 5 && Number(inputTime.slice(-2)) > 60) {
      return setValue(value)
    }
    // 24:0 -> 2
    if (inputTime.length === 4 && value === '24:00') {
      return setValue(inputTime.slice(0, 1))
    }

    // automatically put or remove ':'
    if (inputTime.length === 2 && value.length === 3) {
      return setValue(inputTime.slice(0, -1))
    }
    if (inputTime.length === 2) {
      const hour = Number(inputTime)
      return hour === 24
        ? setValue(inputTime + ':00')
        : setValue(inputTime + ':')
    }

    return setValue(inputTime)
  }

  return (
    <Input
      {...props}
      value={value}
      type="text"
      placeholder={
        props.variant === 'outlined-label' ? props.placeholder : 'hh:mm'
      }
      onChange={handleChange}
    />
  )
}
