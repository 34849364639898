import { Listbox as ListBoxPrimitive, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, type ReactNode } from 'react'
import { cn } from '~/utils/misc.tsx'
import { Icon } from '../../icons/icon.tsx'
import { Separator } from '../../separator.tsx'
import { ToolbarButton } from '../../toolbar.tsx'
import { type AutocompleteOption } from '../forms.tsx'

const SelectRoot = ListBoxPrimitive

const SelectButton = React.forwardRef<
  React.ElementRef<typeof ListBoxPrimitive.Button>,
  React.ComponentPropsWithoutRef<typeof ListBoxPrimitive.Button>
>(({ className, children, ...props }, ref) => (
  <ListBoxPrimitive.Button
    ref={ref}
    aria-label="select trigger"
    className={cn(
      `
relative flex h-11 w-full items-center justify-between whitespace-nowrap rounded-md
border border-input bg-white px-3 py-2 pl-4 text-sm hover:border-foreground focus:border-2
focus:border-foreground focus-visible:border-2 focus-visible:border-foreground
focus-visible:!outline-none disabled:cursor-not-allowed disabled:opacity-50
data-[headlessui-state=open]:border-2 data-[headlessui-state=open]:border-foreground
      `,
      className,
    )}
    {...props}
  >
    <>
      {children}
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <Icon
          name="chevron-down"
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </>
  </ListBoxPrimitive.Button>
))
SelectButton.displayName = ListBoxPrimitive.Button.displayName

type SelectOutlineLabelTriggerProps = {
  className?: string
  placeholder?: string | number
  leftIcon?: React.ReactNode
  label: string
  value?: string | string[]
  open: boolean
  disabled?: boolean
}
const SelectOutlineLabelButton = ({
  open,
  placeholder,
  className,
  leftIcon,
  label,
  value,
  disabled,
}: SelectOutlineLabelTriggerProps) => {
  return (
    <ListBoxPrimitive.Button
      className={cn(
        `
data-[headlessui-state=open]>legend:block relative flex h-11 w-full cursor-pointer
items-center justify-between rounded-lg border border-input px-4 hover:border-foreground
focus:border-2 focus-visible:border-foreground focus-visible:!outline-none
data-[headlessui-state=open]:border-2 data-[headlessui-state=open]:border-foreground
        `,
        disabled && '!cursor-auto !border !border-input',
        className,
      )}
      as="fieldset"
      tabIndex={0}
    >
      <legend
        className={`invisible h-[5px] px-1 text-xs  ${
          value || open ? 'block' : 'hidden'
        }
          `}
      >
        {placeholder}
      </legend>
      <div className="flex gap-2">
        {leftIcon}
        <div
          className={`
absolute origin-top-left duration-100
            ${
              value || open
                ? ` left-5 top-0   -translate-y-2 scale-75 font-semibold text-foreground`
                : ` ${leftIcon ? 'left-10' : 'left-5'}
        top-2.5 translate-y-0  scale-100
        font-normal text-foreground-muted
`
            }
             ${value && !open && '!text-foreground-muted'}

          `}
        >
          {placeholder}
        </div>
        {value && (
          <div className={`z-[3] mt-1 ${!leftIcon && 'pl-[5px]'}`}>{label}</div>
        )}
      </div>
      {!disabled && (
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <Icon
            name="chevron-down"
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      )}
    </ListBoxPrimitive.Button>
  )
}

const SelectToolbarButton = ({
  className,
  label,
}: {
  className?: string
  label: string
}) => {
  return (
    <ListBoxPrimitive.Button>
      <ToolbarButton
        className={cn(
          'flex items-center gap-2 outline-none focus-visible:outline-none',
          className,
        )}
      >
        {label}
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <Icon
            name="chevron-down"
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ToolbarButton>
    </ListBoxPrimitive.Button>
  )
}

export const optionsDefaultClassName =
  'absolute z-[4] mt-1 max-h-[400px] w-full overflow-auto rounded-md bg-popover py-1 text-atoms shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'

const SelectOptions = React.forwardRef<
  React.ElementRef<typeof ListBoxPrimitive.Options>,
  React.ComponentPropsWithoutRef<typeof ListBoxPrimitive.Options>
>(({ className, ...props }, ref) => (
  <ListBoxPrimitive.Options
    ref={ref}
    className={cn(optionsDefaultClassName, className)}
    {...props}
  />
))
SelectOptions.displayName = ListBoxPrimitive.Options.displayName

export const optionDefaultClassName = ({ active }: any) =>
  `relative cursor-default select-none py-2 pl-10 pr-4 ${
    active ? 'bg-accent-100 text-accent' : 'text-foreground'
  } aria-disabled:pointer-events-none aria-disabled:opacity-50 `

const SelectOption = React.forwardRef<
  React.ElementRef<typeof ListBoxPrimitive.Option>,
  React.ComponentPropsWithoutRef<typeof ListBoxPrimitive.Option>
>(({ className, ...props }, ref) => (
  <ListBoxPrimitive.Option
    ref={ref}
    className={({ active }: any) =>
      cn(optionDefaultClassName({ active }), className)
    }
    {...props}
  />
))
SelectOption.displayName = ListBoxPrimitive.Option.displayName

type ResetOptionProps = {
  labelText: string
  value?: string
  labelTrigger?: ReactNode
}
export const resetOption = ({
  labelText,
  value = '',
  labelTrigger,
}: ResetOptionProps) => {
  return {
    label: (
      <>
        <div className="opacity-50">{labelText}</div>
        <Separator className="absolute left-0 right-0 mt-1.5" />
      </>
    ),
    value,
    labelTrigger,
  }
}

const setInitialValue = ({
  value,
  defaultValue,
  multiple,
}: Pick<SelectProps, 'value' | 'defaultValue' | 'multiple'>) => {
  if (value) return value
  if (defaultValue) return defaultValue
  return multiple ? [] : ''
}

export type SelectProps = {
  className?: string
  value?: string | string[] | null
  defaultValue?: string | string[]
  onChange?: (value: string) => void
  placeholder?: string | number
  options: string[] | AutocompleteOption[]
  multiple?: boolean
  optionsClassName?: string
  triggerClassName?: string
  variant?: 'outlined' | 'outlined-label' | 'toolbar' | 'filled'
  leftIcon?: React.ReactNode
  disabled?: boolean
}

const Select = ({
  options,
  value: propValue,
  onChange,
  className,
  placeholder = 'Selecciona...',
  multiple,
  defaultValue,
  optionsClassName,
  triggerClassName,
  variant = 'outlined',
  leftIcon,
  disabled = false,
}: SelectProps) => {
  const [value, setValue] = React.useState<string | string[]>(
    setInitialValue({ value: propValue, defaultValue, multiple }),
  )

  useEffect(() => {
    setValue(setInitialValue({ value: propValue, defaultValue, multiple }))
  }, [defaultValue, propValue, multiple])

  const handleChange = (currentValue: string | string[]) => {
    if (currentValue === value) return
    setValue(currentValue)

    if (onChange) {
      if (typeof currentValue === 'string') {
        onChange(currentValue)
      } else {
        onChange(currentValue.join(','))
      }
    }
  }

  const getOptionValue = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.value
  }
  const getOptionLabel = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.label
  }

  const getLabelTrigger = (value: string | string[]) => {
    if (!value || value.length === 0) {
      return placeholder
    }
    if (typeof value != 'string') {
      // if array, convert array to string
      return value.join(', ')
    }
    const selectedOption = (options as any).find((option: any) => {
      if (typeof option == 'string') {
        return option === value
      }
      return option.value === value
    })
    if (typeof selectedOption == 'string') {
      return selectedOption
    }
    return selectedOption?.labelTrigger ?? selectedOption?.label
  }

  return (
    <SelectRoot
      value={value}
      multiple={multiple}
      onChange={handleChange}
      disabled={disabled}
    >
      {({ open }) => (
        <div className={cn('relative w-full', className)}>
          {variant === 'outlined-label' ? (
            <SelectOutlineLabelButton
              open={open}
              placeholder={placeholder as any}
              leftIcon={leftIcon}
              className={triggerClassName}
              label={getLabelTrigger(value)}
              value={value}
              disabled={disabled}
            />
          ) : variant === 'toolbar' ? (
            <SelectToolbarButton
              label={getLabelTrigger(value)}
              className={triggerClassName}
            />
          ) : variant === 'outlined' ? (
            <SelectButton className={cn('bg-transparent', triggerClassName)}>
              <span className="block truncate">{getLabelTrigger(value)}</span>
            </SelectButton>
          ) : variant === 'filled' ? (
            <SelectButton className={triggerClassName}>
              <span className="block truncate">{getLabelTrigger(value)}</span>
            </SelectButton>
          ) : null}
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <SelectOptions className={optionsClassName}>
              {options.map(option => (
                <SelectOption
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {getOptionLabel(option)}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <Icon
                            name="check"
                            className="h-5 w-5 text-accent-400"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </SelectOption>
              ))}
            </SelectOptions>
          </Transition>
        </div>
      )}
    </SelectRoot>
  )
}

export { SelectRoot, SelectButton, SelectOptions, SelectOption, Select }
