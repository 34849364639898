import { Fragment, type ReactNode, useEffect, useRef, useState } from 'react'
import { Combobox as ComboboxRoot, Transition } from '@headlessui/react'
import { Icon } from '../icons/icon.tsx'
import { optionDefaultClassName } from './select/select.tsx'
import { type AutocompleteOption } from './forms.tsx'
import { cn } from '~/utils/misc.tsx'

const setInitialValue = ({
  value,
  defaultValue,
  multiple,
}: Pick<ComboboxProps, 'value' | 'defaultValue' | 'multiple'>) => {
  if (value) return String(value)
  if (defaultValue) return String(defaultValue)
  return multiple ? [] : ''
}

export type ComboboxOption = AutocompleteOption & {
  value: string | string[] | null
}

export type ComboboxProps = {
  options: string[] | ComboboxOption[]
  className?: string
  value?: string | string[] | null
  defaultValue?: string | string[]
  onChange?: (value: string) => void
  placeholder?: string | number
  multiple?: boolean
  optionsClassName?: string
  triggerClassName?: string
  variant?: 'outlined' | 'filled' | 'outlined-label'
  leftIcon?: React.ReactNode
  autoComplete?: string
  disabled?: boolean
  onInputChange?: (value: string) => void
  notFoundContent?: ReactNode
}

const optionsDefaultClassName = `
absolute  bg-white z-[100] w-full shadow-lg
`
// const optionsDefaultClassName = `
// absolute z-[3] mt-1 max-h-[400px] w-full overflow-auto rounded-md bg-popover
// py-1 text-atoms shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm
// `

export const Combobox = ({
  options,
  value: propValue,
  onChange,
  className,
  placeholder = 'Selecciona...',
  multiple,
  defaultValue,
  optionsClassName,
  triggerClassName,
  variant = 'outlined',
  disabled,
  notFoundContent,
  onInputChange,
}: ComboboxProps) => {
  const [value, setValue] = useState<string | string[]>(
    setInitialValue({ value: propValue, defaultValue, multiple }),
  )
  const [query, setQuery] = useState('')
  const isInitialRender = useRef(true)
  const comboboxButtonRef = useRef<HTMLButtonElement>(null)

  const openOnIntro = (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      comboboxButtonRef?.current?.click()
    } else {
      comboboxButtonRef?.current?.click()
      setQuery(event.key)
      onInputChange && onInputChange(event.key)
    }
  }

  useEffect(() => {
    const combobox = comboboxButtonRef.current
    if (combobox) {
      combobox.tabIndex = 0
      combobox.addEventListener('keypress', openOnIntro)
    }

    return () => {
      combobox?.removeEventListener('keypress', openOnIntro)
    }
  }, [])

  useEffect(() => {
    const newValue = setInitialValue({
      value: propValue,
      defaultValue,
      multiple,
    })
    setValue(newValue)
  }, [propValue, defaultValue])

  // trigger onChange on useEffect instead, to avoid a bug which doesn't close popover if update state on parent component onChange
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    if (onChange) {
      if (typeof value === 'string') {
        onChange(value)
      } else {
        onChange(value.join(','))
      }
    }
  }, [value])

  const filteredOptions =
    query === ''
      ? options
      : (options as any[]).filter((option: any) => {
          // filter by both, value and label
          const optionValue: string =
            typeof option === 'string' ? option : option.value
          const valueFound = optionValue
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
          if (typeof option === 'string') return valueFound

          const optionLabel: string =
            typeof option.label === 'string'
              ? option.label
              : option.labelTrigger
          if (typeof optionLabel !== 'string') return valueFound

          const labelFound = optionLabel
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
          return valueFound || labelFound
        })

  const handleChange = (currentValue: string | string[]) => {
    setQuery('')
    if (currentValue === value) return
    setValue(currentValue)
  }

  const getOptionValue = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.value
  }
  const getOptionLabel = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.label
  }

  function getLabelTrigger(value: string | string[]) {
    if (!value || value.length === 0) {
      return placeholder
    }
    if (typeof value != 'string') {
      // if array, convert array to string
      return value.join(', ')
    }
    const selectedOption = (options as any).find((option: any) => {
      if (typeof option == 'string') {
        return option === value
      }
      return option.value === value
    })
    if (typeof selectedOption == 'string') {
      return selectedOption
    }

    return selectedOption?.labelTrigger ?? selectedOption?.label
  }

  return (
    <ComboboxRoot
      defaultValue={value}
      onChange={handleChange}
      disabled={disabled}
    >
      {({ open }) => (
        <div className={cn('relative w-full', className)}>
          {variant === 'outlined-label' ? (
            <ComboboxOutlinedLabelButton
              comboboxRef={comboboxButtonRef}
              open={open}
              placeholder={placeholder as any}
              // leftIcon={leftIcon}
              className={triggerClassName}
              label={getLabelTrigger(value)}
              value={value}
              disabled={disabled}
            />
          ) : (
            <ComboboxButton
              comboboxRef={comboboxButtonRef}
              placeholder={placeholder as any}
              label={getLabelTrigger(value)}
              value={value}
              className={triggerClassName}
              disabled={disabled}
            />
          )}
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            afterLeave={() => setQuery('')}
          >
            <ComboboxRoot.Options
              className={cn(optionsDefaultClassName, optionsClassName)}
              hold
            >
              <div className="mb-1 flex gap-2 border-b border-input px-4">
                <Icon name="search" className="text-foreground-muted" />
                <ComboboxRoot.Input
                  autoComplete={'off'}
                  className={cn(
                    'h-11 w-full !border-none !outline-none',
                    variant === 'filled' ? 'bg-background' : '',
                  )}
                  placeholder="Buscar..."
                  value={query}
                  displayValue={() => ''}
                  onChange={event => {
                    setQuery(event.target.value)
                    onInputChange && onInputChange(event.target.value)
                  }}
                />
              </div>
              <div className="max-h-[400px] overflow-auto">
                {filteredOptions.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-center text-gray-700">
                    No se encontró ningún resultado
                    {notFoundContent}
                  </div>
                ) : (
                  filteredOptions.map(option => (
                    <ComboboxRoot.Option
                      key={getOptionValue(option)}
                      className={optionDefaultClassName}
                      value={getOptionValue(option)}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {getOptionLabel(option)}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <Icon
                                name="check"
                                className={`h-5 w-5 text-accent-400`}
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ComboboxRoot.Option>
                  ))
                )}
              </div>
            </ComboboxRoot.Options>
          </Transition>
        </div>
      )}
    </ComboboxRoot>
  )
}

type ComboboxButtonProps = {
  className?: string
  label: string
  value?: string | string[]
  disabled?: boolean
  placeholder?: string | number
  comboboxRef: any
}

const ComboboxButton = ({
  className,
  value,
  label,
  disabled,
  placeholder,
  comboboxRef,
}: ComboboxButtonProps) => {
  return (
    <ComboboxRoot.Button
      ref={comboboxRef}
      className={cn(
        `
data-[headlessui-state=open]>legend:block relative flex h-11 w-full cursor-pointer
items-center justify-between rounded-lg border border-input px-5 hover:border-foreground
focus:border-2 focus-visible:border-foreground focus-visible:!outline-none
data-[headlessui-state=open]:border-2 data-[headlessui-state=open]:border-foreground
        `,
        disabled && '!cursor-auto !border !border-input',
        !value && 'text-foreground-muted',
        className,
      )}
      as="div"
    >
      {value ? label : placeholder}
      <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2">
        <Icon
          name="chevron-down"
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
      </div>
    </ComboboxRoot.Button>
  )
}

type SelectOutlineLabelTriggerProps = {
  className?: string
  placeholder?: string | number
  leftIcon?: React.ReactNode
  label: string
  value?: string | string[]
  open: boolean
  disabled?: boolean
  comboboxRef: any
}
const ComboboxOutlinedLabelButton = ({
  open,
  placeholder,
  className,
  leftIcon,
  label,
  value,
  disabled,
  comboboxRef,
}: SelectOutlineLabelTriggerProps) => {
  return (
    <ComboboxRoot.Button
      ref={comboboxRef}
      className={cn(
        `
data-[headlessui-state=open]>legend:block relative flex h-11 w-full cursor-pointer
items-center justify-between rounded-lg border border-input px-4 hover:border-foreground
focus:border-2 focus-visible:border-foreground focus-visible:!outline-none
data-[headlessui-state=open]:border-2 data-[headlessui-state=open]:border-foreground
        `,
        disabled && '!cursor-auto !border !border-input',
        className,
      )}
      as="fieldset"
    >
      <legend
        className={`invisible h-[5px] px-1 text-xs  ${
          value || open ? 'block' : 'hidden'
        }
          `}
      >
        {placeholder}
      </legend>
      <div className="grid gap-2">
        {leftIcon}
        <div
          className={`
absolute origin-top-left duration-100
            ${
              value || open
                ? ` left-5 top-0   -translate-y-2 scale-75 font-semibold text-foreground`
                : ` ${leftIcon ? 'left-10' : 'left-5'}
        top-2.5 translate-y-0  scale-100
        font-normal text-foreground-muted
`
            }
             ${value && !open && '!text-foreground-muted'}

          `}
        >
          {placeholder}
        </div>
        {value && (
          <div className={`z-[3] mt-1 truncate ${!leftIcon && 'pl-[5px]'}`}>
            {label}
          </div>
        )}
      </div>
      {!disabled && (
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <Icon
            name="chevron-down"
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      )}
    </ComboboxRoot.Button>
  )
}
